<template lang="pug">
.terms-conditions-page
	h1 Terms & Conditions
</template>

<script>
export default {
	name: "TermsCondition"
}
</script>

<style lang="scss" scoped>
.terms-conditions-page {
	h1 {
		text-align: center;
	}
}
</style>
